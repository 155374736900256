import { ComponentStyleConfig } from '@chakra-ui/react';

import { colors } from '@/styles/colors';

export const TextareaStyles: ComponentStyleConfig = {
  variants: {
    secondary: () => ({
      bg: `${colors.brand.white}`,
      color: `${colors.brand.gray900}`,
      borderRadius: '16px',
      border: `1px solid ${colors.brand.gray100}`,
      borderWidth: '1px',
      _focus: {
        border: `1px solid ${colors.brand.gray300}`,
      },
    }),
    remark: () => ({
      color: `${colors.brand.gray900}`,
      height: '40px',
      borderRadius: '8px',
      border: `1px solid ${colors.brand.gray200}`,
      bg: `${colors.brand.white}`,
      _disabled: {
        bg: `${colors.brand.gray25}`,
      },
    }),
    bottomSearch: () => ({
      border: `none`,
      borderRadius: '32px',
      padding: '8px 24px',
      width: '100%',
      fontSize: '16px',
      bg: `${colors.brand.blue10}`,
      color: `${colors.brand.gray900}`,
      _focus: {
        border: `none`,
        boxShadow: 'none',
      },
      _placeholder: {
        color: `${colors.brand.gray500}`,
      },
    }),
  },
};
